import React from 'react';
import Head from 'next/head';
import { CONFIG } from '@/constants/config';
import { addLangToUrl, removeLangFromUrl } from '@/utils/helpers';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';

interface Props {
  currentUrl: string;
}

export const HeadMeta = ({ currentUrl }: Props) => {
  const router = useRouter();
  const isHomePage = router.pathname === ROUTES.home;
  return (
    <Head>
      <meta
        key="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
        name="viewport"
      />
      <meta name="google" content="notranslate" />
      <meta
        key="format-detection"
        content="telephone=no"
        name="format-detection"
      />
      <meta key="theme-color" content="#fff" name="theme-color" />

      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/images/favicons/favicon-192x192.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/images/favicons/favicon-192x192.png"
      />

      <meta name="msapplication-TileColor" content="#fff" />
      <meta
        name="msapplication-TileImage"
        content="/images/favicons/ms-icon-144x144.png"
      />
      <meta name="theme-color" content="#fff" />

      <meta
        key="msapplication-config"
        content="/browserconfig.xml"
        name="msapplication-config"
      />

      <link href="/manifest.webmanifest" rel="manifest" />

      {isHomePage && (
        <meta name="apple-itunes-app" content="app-id=0000000001" />
      )}

      {CONFIG.assetHost && (
        <link href={`//${CONFIG.assetHost}`} rel="dns-prefetch" />
      )}

      <link href={currentUrl} rel="canonical" />

      {CONFIG.languages.map(lang => (
        <link
          key={lang}
          hrefLang={lang}
          rel="alternate"
          href={addLangToUrl(removeLangFromUrl(currentUrl), lang)}
        />
      ))}

      <meta name="googlebot" content="index,follow" />
    </Head>
  );
};
